export const FEATURE_FLAGS = {
    GTM: 'fgtm',
    FORMLOADER: 'fformloader',
    LIPSCORE: 'flipscore',
};

// STATE
export interface FeaturesState {
    [key: string]: boolean;
}

// ACTIONS
interface FeaturesAction {
    type: 'SET_FEATURES_SUCCESS';
    payload: FeaturesState;
}

interface FeaturesErrorAction {
    type: 'SET_FEATURES_SUCCESS_ERROR';
}

export type FeaturesActionTypes = FeaturesAction | FeaturesErrorAction;
