import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useAppQuery from 'queries/useAppQuery';
import useFeatureFlagQuery from 'queries/useFeatureFlagQuery';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import DataLayer from '@activebrands/core-web/components/tracking/DataLayer';
import { useDidUpdate } from '@activebrands/core-web/hooks/lifecycle';
import Events from '@activebrands/core-web/libs/Events';
import { CheckoutEvents, PageEvents, ProductEvents } from '@activebrands/core-web/libs/Events/types';
import { GetTeamStoreData } from '@activebrands/core-web/libs/grebcommerce/auth';
import overlay from '@activebrands/core-web/libs/overlay';
import { setApplication } from '@activebrands/core-web/state/application/actions';
import { authenticate } from '@activebrands/core-web/state/auth/actions';
import { getBasket } from '@activebrands/core-web/state/basket/actions';
import { getBasketIdCookie } from '@activebrands/core-web/state/basket/utils';
import { setFeatures } from '@activebrands/core-web/state/features/actions';
import { setProduct } from '@activebrands/core-web/state/product/actions';
import { addRecentlyViewedListItem } from '@activebrands/core-web/state/recentlyViewed/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import { setTeamStoreData as setTeamStoreDataRedux } from '@activebrands/core-web/state/teamStore/actions';
import { resolveUser } from '@activebrands/core-web/state/user/actions';
import { inServer, isStoryblokPreview } from '@activebrands/core-web/utils/constants';
import replaceObjectValue from '@activebrands/core-web/utils/replace-object-value';
import { formatCentraFeedId } from '@activebrands/core-web/utils/tracking';
import isObject from '@grebban/utils/object/isObject';

const PageContainer = ({ page, children }) => {
    const dispatch = useTypedDispatch();
    const application = useAppQuery();
    const featureFlagConfig = useFeatureFlagQuery();
    const { currency } = application.pricelist || {};
    const contactId = useSelector(state => state?.auth?.user?.contactId);
    const teamId = useSelector(state => state?.auth?.user?.teams?.[0]?.tag);
    const token = useSelector(state => state?.auth?.user?.token);
    const user = useSelector(state => state?.auth);

    const basketId = useSelector(state => state?.basket?.id);
    const basketCountry = useSelector(state => state?.basket?.country);
    const basketItems = useSelector(state => state?.basket?.items);
    const basketDiscount = useSelector(state => state?.basket?.totalDiscount);

    // Old database data could come with value null, replace these values with true to prevent some scripts not running
    replaceObjectValue(featureFlagConfig, null, true);
    dispatch(setFeatures(featureFlagConfig));

    const [teamStoreData, setTeamStoreData] = useState(useSelector(state => state?.teamStore));

    let [isAuthenticated] = useSelector(
        s => [s.auth.isAuthenticated],
        (p, n) => p[0] === n[0] && p[1] === n[1]
    );

    const [isLoading] = useSelector(
        s => [s.auth.isLoading],
        (p, n) => p[0] === n[0] && p[1] === n[1]
    );

    isAuthenticated = isStoryblokPreview || isAuthenticated;

    const { siteSelectorLinks } = useSiteSelectorQuery();
    const userCountry = useSelector(state => state.application.shopConfig.userCountry);

    if (inServer) {
        dispatch(setApplication({ application, sites: siteSelectorLinks }));

        if (page.type === 'product' && page.data) {
            dispatch(setProduct(page.data));
        }
    }

    // We fetch the team store data here because we need to know if the Team Store is open to take
    // orders or not in order to block adding of products to the basket in several places.
    useEffect(() => {
        const fetchData = async () => {
            let isMounted = true;
            if (teamId && token && !teamStoreData.variantSkus.length) {
                try {
                    const data = await GetTeamStoreData({ teamId }, token);

                    if (isMounted) {
                        const parsedData = {
                            deliveryWeek: data.team?.deliveryWeek?.substring(6, 4),
                            orderingStartDate: data?.team?.startDate,
                            orderingEndDate: data?.team?.deadline,
                            variantSkus: data?.products?.map(product => product.number + teamId),
                            products: [],
                        };
                        setTeamStoreData({ ...teamStoreData, ...parsedData });
                        dispatch(setTeamStoreDataRedux({ ...teamStoreData, ...parsedData }));
                    }
                } catch (error) {
                    console.error('Error fetching team store data:', error);
                }

                return () => {
                    isMounted = false;
                };
            }
        };

        isAuthenticated && fetchData();
    }, [isAuthenticated, teamId, token]);

    useEffect(() => {
        dispatch(setApplication({ application, sites: siteSelectorLinks }));
        dispatch(resolveUser());
    }, []);

    useEffect(() => {
        if (!isLoading && isAuthenticated === undefined) {
            dispatch(authenticate());
        }
    }, [isLoading, isAuthenticated]);

    useEffect(() => {
        const basketId = getBasketIdCookie();

        // Dont try to fetch the basket on checkout response page
        if (basketId && page.type !== 'checkoutResponse') {
            dispatch(getBasket(basketId));
        }
    }, []);

    useEffect(() => {
        if (page.type !== 'categoryPage' && page.data) {
            Events.trigger(PageEvents.VIEW, {
                currencyCode: currency,
                locale: application.application.locale,
                pageTitle: page.data.name,
                userId: contactId,
            });
        }

        if (page.type === 'product' && page.data) {
            // Add product to RecentlyViewedList in redux.
            dispatch(addRecentlyViewedListItem(page.data));

            // Set product
            dispatch(setProduct(page.data));
            const firstVariant = page.data?.variations[0];
            const centraId = isObject(firstVariant) ? formatCentraFeedId({ ...firstVariant }, userCountry) : undefined;

            const eventData = {
                currencyCode: currency,
                product: {
                    id: centraId,
                    categories: page.data.categories,
                    sku: firstVariant ? firstVariant.sku : page.data.sku,
                    name: page.data.name,
                    price: page.data.price,
                },
            };

            Events.trigger(ProductEvents.VIEW, eventData);
        }
    }, [page.external_id]);

    useEffect(() => {
        if (page.type === 'checkoutPage' && page.data && basketId) {
            Events.trigger(CheckoutEvents.VIEW, {
                products: DataLayer.basketItems(basketItems ?? [], basketCountry),
                currencyCode: currency,
                vouchers: basketDiscount?.discounts.map(discount => discount.id),
                user: {
                    email: user?.email ?? null,
                    phoneNumber: user?.phoneNumber ?? null,
                },
            });
        }
    }, [page.external_id, basketId]);

    useDidUpdate(() => {
        overlay.closeAll();
    }, [page.slug]);

    return children;
};

PageContainer.propTypes = {
    page: PropTypes.object.isRequired,
};

export default PageContainer;
