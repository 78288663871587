const PREFIX = process.env.REACT_APP_PREFIX;

export const unAllowedStreetNameCharsRegExp = () => {
    return new RegExp(/[!$%*\\<=>?\[\]^{|}~]/);
};

export const isValidNameRegExp = () => {
    return new RegExp(/[!"#$%&()*+,\\/:;<=>?@\[\]^_`{|}~°ºᵃª]/);
};

export const isValidExtendedLatinRegExp = () => {
    try {
        return new RegExp(
            /^(?!.*[\p{Script=Arabic}\p{Script=Cyrillic}\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}]).*$/u
        );
    } catch (e) {
        /**
         * Fallback for older browsers
         * Arabic: \u0600-\u06FF
         * Cyrillic: \u0400-\u04FF\u0500-\u052F
         * Hiragana: \u3040-\u309F
         * Katakana: \u30A0-\u30FF
         * Han (Chinese): \u4E00-\u9FFF
         */
        return new RegExp(/^(?!.*[\u0600-\u06FF\u0400-\u04FF\u0500-\u052F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]).*$/);
    }
};

export const containNumbersRegExp = () => {
    return new RegExp(/\d/);
};

export const numbersOnlyBeforeAtRegExp = () => {
    return new RegExp(/^[0-9]+@/);
};

export const numbersOnlyAfterAtRegExp = () => {
    return new RegExp(/@([0-9]+)\./);
};

export const isValidEmailRegExp = () => {
    return new RegExp(
        /(?<local>^[a-zA-Z0-9!#$%&'+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'+/=?^_`{|}~-]+)*)@(?<domain>(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+(?<tld>[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$))/
    );
};

/**
 * Somewhat complete list of zip codes
 * see https://en.wikipedia.org/wiki/List_of_postal_codes
 * and https://stackoverflow.com/questions/578406/what-is-the-ultimate-postal-code-and-zip-regex
 * NOTE! See this list if zipcodes needs to be updated or new added:
 * https://gist.github.com/ShreyKumar/43ebb040383c90ed976439245423aa38
 */

const zipCodeRegExp: { [key: string]: RegExp } = {
    'AD': /^AD\d{3}$/,
    'AI': /^AI-2640/,
    'AM': /^(37)?\d{4}$/,
    'AR': /^([A-HJ-NP-Z])?\d{4}([A-Z]{3})?$/,
    'AS': /^96799$/,
    'AT': /^\d{4}$/,
    'AU': /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/,
    'AX': /^22\d{3}$/,
    'AZ': /^\d{4}$/,
    'BA': /^\d{5}$/,
    'BB': /^(BB\d{5})?$/,
    'BD': /^\d{4}$/,
    'BE': /^[1-9]{1}[0-9]{3}$/,
    'BG': /^\d{4}$/,
    'BH': /^((1[0-2]|[2-9])\d{2})?$/,
    'BM': /^[A-Z]{2}[ ]?[A-Z0-9]{2}$/,
    'BN': /^[A-Z]{2}[ ]?\d{4}$/,
    'BR': /^\d{5}[\-]?\d{3}$/,
    'BY': /^\d{6}$/,
    'CA': /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])\ {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/,
    'CC': /^6799$/,
    'CH': /^\d{4}$/,
    'CK': /^\d{4}$/,
    'CL': /^\d{7}$/,
    'CN': /^\d{6}$/,
    'CR': /^\d{4,5}|\d{3}-\d{4}$/,
    'CS': /^\d{5}$/,
    'CV': /^\d{4}$/,
    'CX': /^6798$/,
    'CY': /^\d{4}$/,
    'CZ': /^\d{3}[ ]?\d{2}$/,
    'DE': /\b((?:0[1-46-9]\d{3})|(?:[1-357-9]\d{4})|(?:[4][0-24-9]\d{3})|(?:[6][013-9]\d{3}))\b/,
    'DK': /^([D|d][K|k]( |-))?[1-9]{1}[0-9]{3}$/,
    'DO': /^\d{5}$/,
    'DZ': /^\d{5}$/,
    'EC': /^([A-Z]\d{4}[A-Z]|(?:[A-Z]{2})?\d{6})?$/,
    'EE': /^\d{5}$/,
    'EG': /^\d{5}$/,
    'ES': /^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$/,
    'ET': /^\d{4}$/,
    'FI': /^\d{5}$/,
    'FK': /^FIQQ 1ZZ$/,
    'FM': /^(9694[1-4])([ \-]\d{4})?$/,
    'FO': /^\d{3}$/,
    'FR': /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/,
    'GB': /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
    'GE': /^\d{4}$/,
    'GF': /^9[78]3\d{2}$/,
    'GG': /^GY\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
    'GL': /^39\d{2}$/,
    'GN': /^\d{3}$/,
    'GP': /^9[78][01]\d{2}$/,
    'GR': /^\d{3}[ ]?\d{2}$/,
    'GS': /^SIQQ 1ZZ$/,
    'GT': /^\d{5}$/,
    'GU': /^969[123]\d([ \-]\d{4})?$/,
    'GW': /^\d{4}$/,
    'HM': /^\d{4}$/,
    'HN': /^(?:\d{5})?$/,
    'HR': /^\d{5}$/,
    'HT': /^\d{4}$/,
    'HU': /^\d{4}$/,
    'ID': /^\d{5}$/,
    'IL': /^\d{5}(\d{2})?$/,
    'IM': /^IM\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
    'IN': /^\d{6}$/,
    'IO': /^BBND 1ZZ$/,
    'IQ': /^\d{5}$/,
    'IS': /^\d{3}$/,
    'IT': /^(V-|I-)?[0-9]{5}$/,
    'JE': /^JE\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}$/,
    'JO': /^\d{5}$/,
    'JP': /^\d{3}-\d{4}$/,
    'KE': /^\d{5}$/,
    'KG': /^\d{6}$/,
    'KH': /^\d{5}$/,
    'KR': /^\d{3}[\-]?\d{2,3}$/,
    'KW': /^\d{5}$/,
    'KZ': /^\d{6}$/,
    'LA': /^\d{5}$/,
    'LB': /^(\d{4}([ ]?\d{4})?)?$/,
    'LI': /^(948[5-9])|(949[0-7])$/,
    'LK': /^\d{5}$/,
    'LR': /^\d{4}$/,
    'LS': /^\d{3}$/,
    'LT': /^\d{5}$/,
    'LU': /^\d{4}$/,
    'LV': /^(LV)?(\s|-)?\d{4}$/,
    'MA': /^\d{5}$/,
    'MC': /^980\d{2}$/,
    'MD': /^\d{4}$/,
    'ME': /^8\d{4}$/,
    'MG': /^\d{3}$/,
    'MH': /^969[67]\d([ \-]\d{4})?$/,
    'MK': /^\d{4}$/,
    'MN': /^\d{6}$/,
    'MP': /^9695[012]([ \-]\d{4})?$/,
    'MQ': /^9[78]2\d{2}$/,
    'MT': /^[A-Z]{3}[ ]?\d{2,4}$/,
    'MU': /^\d{5}$/,
    'MV': /^\d{5}$/,
    'MX': /^\d{5}$/,
    'MY': /^\d{5}$/,
    'NC': /^988\d{2}$/,
    'NE': /^\d{4}$/,
    'NF': /^2899$/,
    'NG': /^(\d{6})?$/,
    'NI': /^((\d{4}-)?\d{3}-\d{3}(-\d{1})?)?$/,
    'NL': /^[1-9][0-9]{3}\s?([a-zA-Z]{2})?$/,
    'NO': /^\d{4}$/,
    'NP': /^\d{5}$/,
    'NZ': /^\d{4}$/,
    'OM': /^(PC )?\d{3}$/,
    'PF': /^987\d{2}$/,
    'PG': /^\d{3}$/,
    'PH': /^\d{4}$/,
    'PK': /^\d{5}$/,
    'PL': /^\d{2}-\d{3}$/,
    'PM': /^9[78]5\d{2}$/,
    'PN': /^PCRN 1ZZ$/,
    'PR': /^00[679]\d{2}([ \-]\d{4})?$/,
    'PT': /^\d{4}([\-]\d{3})?$/,
    'PW': /^96940$/,
    'PY': /^\d{4}$/,
    'RE': /^9[78]4\d{2}$/,
    'RO': /^\d{6}$/,
    'RS': /^\d{6}$/,
    'RU': /^\d{6}$/,
    'SA': /^\d{5}$/,
    'SE': /^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$/,
    'SG': /^\d{6}$/,
    'SH': /^(ASCN|STHL) 1ZZ$/,
    'SI': /^\d{4}$/,
    'SJ': /^\d{4}$/,
    'SK': /^\d{3}[ ]?\d{2}$/,
    'SM': /^4789\d$/,
    'SN': /^\d{5}$/,
    'SO': /^\d{5}$/,
    'SZ': /^[HLMS]\d{3}$/,
    'TC': /^TKCA 1ZZ$/,
    'TH': /^\d{5}$/,
    'TJ': /^\d{6}$/,
    'TM': /^\d{6}$/,
    'TN': /^\d{4}$/,
    'TR': /^\d{5}$/,
    'TW': /^\d{3}(\d{2})?$/,
    'UA': /^\d{5}$/,
    'US': /^\d{5}([ \-]\d{4})?$/,
    'UY': /^\d{5}$/,
    'UZ': /^\d{6}$/,
    'VA': /^00120$/,
    'VE': /^\d{4}$/,
    'VI': /^008(([0-4]\d)|(5[01]))([ \-]\d{4})?$/,
    'WF': /^986\d{2}$/,
    'XK': /^\d{5}$/,
    'YT': /^976\d{2}$/,
    'YU': /^\d{5}$/,
    'ZA': /^\d{4}$/,
    'ZM': /^\d{5}$/,
};

export const isValidZipCodeRegExp = (countryCode: string | null) => {
    return new RegExp(zipCodeRegExp[countryCode || '']);
};

export const generateSrcRegExp = (key: string) => {
    return new RegExp(`(?:${key})\\=\\d+`, 'g');
};

export const generateSrcSetRegExp = (heights: number[]) => {
    return new RegExp(`(\\?|\\&){0,1}(?:${[heights].length ? 'w|h' : 'w'})\\=\\d+\\&{0,1}`, 'g');
};

export const isFileRegExp = () => {
    return new RegExp(/.+\.[a-zA-Z0-9]+$/);
};

export const removePathPrefixRegExp = () => {
    return new RegExp(`^/${PREFIX}`);
};
