import { PdpCssVariables } from 'config/branding/interfaces/pdp';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const pdpCssVariables: PdpCssVariables = {
    '--color-border-thumbnail': 'var(--color-base-white)',
    '--color-border-thumbnail-active': 'var(--color-base-brand-orange)',
    '--color-bg-swipe-indicator': 'var(--color-base-black-20)',
    '--color-bg-swipe-indicator-active': 'var(--color-base-brand-orange)',
    '--color-bg-gallery-placeholder': 'var(--color-base-brand-grey-1)',
    '--color-bg-model-size': 'var(--color-base-white)',
    '--color-bg-pdp-breadcrumbs': 'var(--color-base-white)',
    '--color-bg-pdp-important-content': 'var(--color-base-white)',
    '--color-bg-variant-sale-badge': 'var(--color-base-error)',
    '--color-border-pdp-accordion-content': 'var(--color-base-brand-black)',
    '--color-border-pdp-accordions': 'var(--color-base-brand-grey-1)',
    '--color-border-pdp-add-to-basket-button-hover': 'var(--color-base-brand-orange)',
    '--color-border-pdp-flipclock': 'var(--color-base-brand-grey-1)',
    '--color-border-pdp-important-content': 'var(--color-base-brand-grey-1)',
    '--color-border-pdp-size-selector-active': 'var(--color-base-brand-orange)',
    '--color-border-pdp-size-selector': 'var(--color-base-brand-grey-2)',
    '--color-border-pdp-upsell-products': 'var(--color-base-brand-grey-1)',
    '--color-text-pdp-id': 'var(--color-base-brand-grey-3)',
    '--color-text-pdp-price-history': 'var(--color-base-brand-grey-3)',
    '--color-text-variant-sale-badge': 'var(--color-base-white)',
};
