import { Media, ProductPageViewMedia } from '@activebrands/core-web/types/product';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';

const transformProductPageViewMedia = ({ images, source, videoSrc }: ProductPageViewMedia): Media[] => {
    if (!images?.length) {
        return [];
    }

    const maxImages = videoSrc ? 11 : 12;

    if (source == 'Algolia') {
        return images.slice(0, maxImages).map(item => {
            return {
                alt: item.alt,
                type: 'image',
                typeValue: item.type,
                url: item.url,
            };
        }) as Media[];
    }

    const transformedMedia = images.slice(0, maxImages).map(item => {
        const image = objectKeysToCamelCase(item || {});
        return {
            alt: image.alt,
            modelSizeImperial: image.attributes?.attrModelImperialSize,
            modelSizeMetric: image.attributes?.attrModelMetricSize,
            type: 'image',
            typeValue: image.attributes?.attrTypeValue || image.typeValue,
            url: image.sizes?.original?.url || image.url,
        };
    }) as Media[];

    if (videoSrc) {
        // Add video to the second position
        transformedMedia.splice(1, 0, {
            type: 'video',
            url: videoSrc,
        });
    }

    return transformedMedia;
};

export default transformProductPageViewMedia;
