import aa from 'search-insights';

const INDEX_NAME = `${process.env.REACT_APP_ALGOLIA_PREFIX}products_${(process.env.REACT_APP_ALGOLIA_LANGUAGE_CODE as string) || ''}`

export interface PurchasedObjectIDsData {
    eventName: string;
    index: string;
    value: number;
    currency: string;
    userToken: string;
    items: {
        productId: string;
        priceEachAsNumber: number;
        quantity: number;
        priceEachReductionAsNumber: number;
    }[];
    currencyCode: string;
    totals: {
        finalPriceAsNumber: number;
    }
}

export default (data: PurchasedObjectIDsData) => {
    aa('purchasedObjectIDs', {
        eventName: 'Transaction Completed',
        index: INDEX_NAME,
        objectIDs: data.items.map(product => product.productId),
        objectData: data.items.map(product => ({
            price: product.priceEachAsNumber,
            quantity: product.quantity,
            discount: product.priceEachReductionAsNumber,
        })),
        value: data.totals.finalPriceAsNumber,
        currency: data.currencyCode,
    });
};
