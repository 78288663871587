import Events from '@activebrands/core-web/libs/Events';
import { AccountEvents } from '@activebrands/core-web/libs/Events/types';
import {
    ChangePassword,
    CreateUser,
    GetOrders,
    GetUser,
    IsEmailRegistered,
    NewsletterSignUpBrand,
    NewsletterSignUpLanguageCode,
    ResetPassword,
    SendResetPasswordLink,
    SignIn,
    SignUpNewsletter,
    UpdateConsent,
    UpdatePreferences,
    UpdateUser,
    ValidateTeamStoreAccount,
} from '@activebrands/core-web/libs/grebcommerce/auth';
import { StoreAction, StoreDispatch } from '@activebrands/core-web/state/store';
import getSessionAuthToken from '@activebrands/core-web/utils/auth/get-session-auth-token';
import parseError from '@activebrands/core-web/utils/auth/parse-error';
import removeSessionAuthToken from '@activebrands/core-web/utils/auth/remove-session-auth-token';
import setSessionAuthToken from '@activebrands/core-web/utils/auth/set-session-auth-token';
import { logger } from '@activebrands/core-web/utils/logger';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';

const NEWSLETTER_SIGNUP_BRAND = process.env.REACT_APP_NEWSLETTER_SIGNUP_BRAND as NewsletterSignUpBrand;
const NEWSLETTER_SIGNUP_LANGUAGE_CODE = process.env
    .REACT_APP_NEWSLETTER_SIGNUP_LANGUAGE_CODE as NewsletterSignUpLanguageCode;

type Credentials = {
    email?: string;
    password?: string;
};

export const authenticate = (): StoreAction => async dispatch => {
    const userToken = getSessionAuthToken();

    if (!userToken) {
        return;
    }

    dispatch({ type: 'AUTH_AUTHENTICATE' });

    try {
        // Skip user fetch if token is not defined
        const userData = userToken ? await GetUser(userToken) : null;

        if (!!userData) {
            const isMember = userData?.customer?.tags?.length > 0;

            const user = {
                ...userData.customer,
                token: userToken,
            };

            if (isMember) {
                dispatch({
                    type: 'AUTH_AUTHENTICATE_SUCCESS',
                    payload: { user },
                });
            } else {
                dispatch({
                    type: 'AUTH_AUTHENTICATE_AWAIT_CONSENT',
                    payload: { user },
                });
            }
        }
    } catch (error) {
        dispatch({ type: 'AUTH_SIGN_OUT_SUCCESS' });
    }
};

export const signIn =
    ({ email, password }: Credentials) =>
    async dispatch => {
        dispatch({ type: 'AUTH_SIGN_IN' });

        if (email && password) {
            const loginResult: any = await SignIn(email, password)
                .then(async response => {
                    if (!response.firebaseResponse.idToken) {
                        throw { ...parseError(response) };
                    }

                    const token = response?.firebaseResponse?.idToken;

                    setSessionAuthToken(token);

                    const userData = await GetUser(token);

                    const isMember = userData?.customer?.tags?.length > 0;

                    const user = {
                        ...userData.customer,
                        isMember,
                        token,
                    };

                    if (isMember) {
                        dispatch({
                            type: 'AUTH_AUTHENTICATE_SUCCESS',
                            payload: { user },
                        });

                        Events.trigger(AccountEvents.SIGN_IN, {
                            userId: user?.contactId,
                            customerNumber: user?.customerNumber,
                        });
                    } else {
                        dispatch({
                            type: 'AUTH_AUTHENTICATE_AWAIT_CONSENT',
                            payload: { user },
                        });
                    }

                    return user;
                })
                .catch(response => {
                    const error = parseError(response);

                    dispatch({
                        type: 'AUTH_SIGN_IN_ERROR',
                        payload: { error },
                    });
                    return error;
                });
            return loginResult;
        }
    };

// export const signInWithFacebook = () => async dispatch => {
//     dispatch({ type: 'AUTH_SIGN_IN_WITH_PROVIDER' });

//     try {
// const loginResult = await GrebbCommerceFirebase.loginWith.facebook();
// const userData = loginResult.user;
//         const userToken = await userData.getIdToken();
//         const userRequest = await GetUser(userToken);
//         const userRequestData = userRequest.data;
//         const user = parseUserData(userRequestData);

//         dispatch({
//             type: 'AUTH_SIGN_IN_WITH_PROVIDER_SUCCESS',
//             payload: { user },
//         });

//         return user;
//     } catch (error) {
//         dispatch({
//             type: 'AUTH_SIGN_IN_WITH_PROVIDER_ERROR',
//             payload: { error },
//         });
//     }
// };

// export const signInWithGoogle = () => async dispatch => {
//     dispatch({ type: 'AUTH_SIGN_IN_WITH_PROVIDER' });

//     try {
// const loginResult = await GrebbCommerceFirebase.loginWith.google();
// const userData = loginResult.user;
//         const userToken = await userData.getIdToken();
//         const userRequest = await GetUser(userToken);
//         const userRequestData = userRequest.customer;

//         // TODO: Add this when vouchers is available from google sign in provider
//         // const userVouchers = await GetUserVouchers(userToken);

//         const user = parseUserData(userRequestData);

//         dispatch({
//             type: 'AUTH_SIGN_IN_WITH_PROVIDER_SUCCESS',
//             payload: { user },
//         });

//         return { user };
//     } catch (error) {
//         dispatch({
//             type: 'AUTH_SIGN_IN_WITH_PROVIDER_ERROR',
//             payload: { error },
//         });
//     }
// };

export const signOut = () => async (dispatch, getState) => {
    dispatch({ type: 'AUTH_SIGN_OUT' });

    const userState = getState().auth;
    const { isAuthenticated } = userState;

    if (!isAuthenticated) {
        return false;
    }

    try {
        removeSessionAuthToken();
        dispatch({ type: 'AUTH_SIGN_OUT_SUCCESS' });
        return true;
    } catch (error) {
        dispatch({ type: 'AUTH_SIGN_OUT_ERROR', payload: { error } });

        return { error };
    }
};

export const signUp =
    ({ email, password }) =>
    async dispatch => {
        dispatch({ type: 'AUTH_SIGN_UP' });

        try {
            const response = await CreateUser(email, password);

            dispatch({ type: 'AUTH_SIGN_UP_SUCCESS' });
            Events.trigger(AccountEvents.SIGN_UP, {
                userId: response.customer.contactId,
                customerNumber: response.customer.customerNumber,
            });

            return { status: 200 };
        } catch (error) {
            const payload = parseError(error);

            dispatch({ type: 'AUTH_SIGN_UP_ERROR', payload });

            return payload;
        }
    };

export const updateUser =
    ({
        acknowledgement,
        addressLine1,
        birthday,
        city,
        country,
        email,
        firstname,
        gender,
        interestParams,
        lastname,
        mobilePhone,
        postalCode,
        permissions,
        token,
    }) =>
    async dispatch => {
        dispatch({ type: 'AUTH_UPDATE_USER' });

        try {
            const response = await UpdateUser(
                {
                    addressLine1,
                    acknowledgement,
                    birthday,
                    city,
                    country,
                    email,
                    firstname,
                    gender,
                    interestParams,
                    lastname,
                    mobilePhone,
                    postalCode,
                    permissions,
                },
                token
            );

            // This is a 200 response from the ACT API
            if (response.message === 'Put request success.') {
                const newUserData = await GetUser(token);

                dispatch({ type: 'AUTH_UPDATE_USER_SUCCESS', payload: newUserData.customer });
                return response;
            }

            throw response;
        } catch (error) {
            const payload = parseError(error);

            dispatch({ type: 'AUTH_UPDATE_USER_ERROR', payload });
            return payload;
        }
    };

export const updateConsent =
    ({ token, brand, consent, contactInformation, contactType, language }) =>
    async dispatch => {
        dispatch({ type: 'AUTH_UPDATE_USER' });

        try {
            const response = await UpdateConsent(token, { brand, consent, contactInformation, contactType, language });

            if (response?.Permissions) {
                dispatch({
                    type: 'AUTH_UPDATE_USER_SUCCESS',
                    payload: {
                        permissions: objectKeysToCamelCase(response.Permissions),
                    },
                });

                return response;
            }

            throw new Error('Error updating consent, missing permissions');
        } catch (error) {
            const payload = parseError(error);

            dispatch({ type: 'AUTH_UPDATE_USER_ERROR', payload });
            return payload;
        }
    };

export const updatePreferences = (preferences: { pref: 'Email' | string; value: boolean }, token) => async dispatch => {
    dispatch({ type: 'AUTH_UPDATE_USER' });

    try {
        const response = await UpdatePreferences(token, preferences);

        dispatch({
            type: 'AUTH_UPDATE_USER_SUCCESS',
            payload: {
                preferences: response.preferences,
            },
        });

        return response;
    } catch (error) {
        const payload = { error: parseError(error) };

        dispatch({ type: 'AUTH_UPDATE_USER_ERROR', payload });

        return payload;
    }
};

export const sendResetPasswordLink = (email: string) => async (dispatch, getState) => {
    dispatch({ type: 'AUTH_SEND_RESET_PASSWORD_LINK' });

    const { aliasId } = getState().application;

    try {
        const response = await SendResetPasswordLink(email, aliasId);

        dispatch({ type: 'AUTH_SEND_RESET_PASSWORD_LINK_SUCCESS' });

        return response;
    } catch (error) {
        dispatch({ type: 'AUTH_SEND_RESET_PASSWORD_LINK_ERROR', payload: { error } });

        return { error };
    }
};

export const resetPassword = (email: string) => async dispatch => {
    dispatch({ type: 'AUTH_RESET_PASSWORD' });

    try {
        const response = await ResetPassword(email);

        dispatch({ type: 'AUTH_RESET_PASSWORD_SUCCESS' });

        return response;
    } catch (error) {
        dispatch({ type: 'AUTH_RESET_PASSWORD_ERROR', payload: { error } });

        return { error };
    }
};

export const changePassword =
    ({ email = '', oldPassword = '', newPassword = '', token = '' }) =>
    async dispatch => {
        dispatch({ type: 'AUTH_CHANGE_PASSWORD' });

        try {
            const response = await ChangePassword({ email, oldPassword, newPassword }, token);

            dispatch({ type: 'AUTH_CHANGE_PASSWORD_SUCCESS' });

            return response;
        } catch (error) {
            dispatch({ type: 'AUTH_CHANGE_PASSWORD_ERROR', payload: { error } });

            return { error };
        }
    };

export const getOrders = (token: string) => async (dispatch: StoreDispatch) => {
    dispatch({ type: 'AUTH_UPDATE_USER' });

    try {
        const response = await GetOrders(token);

        dispatch({
            type: 'AUTH_UPDATE_USER_SUCCESS',
            payload: {
                orders: response,
            },
        });

        return response;
    } catch (error) {
        dispatch({ type: 'AUTH_UPDATE_USER_ERROR', payload: { error } });

        return { error };
    }
};

export const checkIfEmailIsRegistered = (email: string) => async dispatch => {
    dispatch({ type: 'AUTH_CHECK_EMAIL' });

    try {
        const response = await IsEmailRegistered(email);

        dispatch({
            type: 'AUTH_CHECK_EMAIL_SUCCESS',
        });

        return response;
    } catch (error) {
        dispatch({ type: 'AUTH_CHECK_EMAIL_ERROR', payload: { error } });
    }
};

export const validateTeamStoreAccount =
    ({ teamId, teamPassword, token }) =>
    async dispatch => {
        dispatch({ type: 'AUTH_VALIDATE_TEAM_STORE_ACCOUNT' });

        try {
            const response = await ValidateTeamStoreAccount({ teamId, teamPassword }, token);

            if (response.Message === 'Member has been added marketing list') {
                const newUserData = await GetUser(token);

                dispatch({
                    type: 'AUTH_VALIDATE_TEAM_STORE_ACCOUNT_SUCCESS',
                    payload: newUserData.customer,
                });

                return response;
            }

            throw response;
        } catch (error) {
            const payload = { error: parseError(error) };
            dispatch({ type: 'AUTH_VALIDATE_TEAM_STORE_ACCOUNT_ERROR', payload });
        }
    };

export const signUpNewsletter =
    ({ userEmail }) =>
    async dispatch => {
        dispatch({ type: 'AUTH_UPDATE_USER' });

        try {
            if (!userEmail || typeof userEmail !== 'string') {
                throw new Error('Invalid email address provided');
            }

            const response = await SignUpNewsletter({
                brand: NEWSLETTER_SIGNUP_BRAND,
                consent: 'OptIn',
                contactInformation: userEmail,
                contactType: 'Email',
                language: NEWSLETTER_SIGNUP_LANGUAGE_CODE,
            });

            if (!response || !response.data) {
                throw new Error('Invalid or missing response from newsletter signup');
            }

            const permissions = objectKeysToCamelCase(JSON.parse(response.data)?.Permissions);
            if (!permissions) {
                throw new Error('Failed to parse permissions from response');
            }

            dispatch({
                type: 'AUTH_UPDATE_USER_SUCCESS',
                payload: { permissions },
            });

            Events.trigger(AccountEvents.EMAIL_SIGNUP, {});

            return response;
        } catch (error) {
            const errorMessage = (error as Error)?.message || 'Unknown error occurred during newsletter signup';
            const payload = parseError(error);

            logger.error('Error in signUpNewsletter', {
                errorMessage,
                errorDetails: error,
                userEmail,
            });

            dispatch({ type: 'AUTH_UPDATE_USER_ERROR', payload });
            return payload;
        }
    };
